import React, { useMemo } from 'react';

import MarqueeLink, { LINK_MODES } from '~/components/shared/Marquee/Link';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import './Marquee.scss';

export default function MarqueeCallouts({ list = [] }) {
  const columns = useMemo(
    () => defaultForUndefinedOrNull(list?.length, 0),
    [list?.length]
  );

  return (
    <div className="c-nsCallout u-b-primary">
      <ul className={`c-nsCallout__list u-minied-list is-${columns}col`}>
        {list.map((props, idx) => (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className={`c-nsCallout__child is-p${props?.position}`}
          >
            <MarqueeLink
              blockName="c-nsCallout"
              mode={LINK_MODES?.callout}
              position={props?.position}
              safeInnerHtml={props?.safeInnerHtml}
              prefixIcon={props?.prefixIcon}
              suffixIcon={props?.suffixIcon}
              link={props?.link}
              title={props?.tooltip}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
