import axios from 'axios';
import queryString from 'query-string-for-all';

const errorHandler = (error) => {
  if (!axios.isCancel(error)) {
    let res;
    const buildKey = (name) => `REQUEST_AXIOS_ERROR_${name}`;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errObj = { error: error.response };
      const { status } = error.response;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const errObj = { error: error.request };
      const { status } = error.request;
      res = {
        key: buildKey(status),
        status,
        ...errObj
      };
      window[res] = errObj;
    } else {
      // Something happened in setting up the request that triggered an Error
      res = {
        key: `REQUEST_AXIOS_ERROR: ${error.message}`,
        status: -1,
        ...error
      };
      window.REQUEST_AXIOS_ERROR = { error };
    }
    return res;
  }
  return {
    message: 'Opps! Something went wrong while setting up request',
    status: -1
  };
};

const cancelSource = axios.CancelToken.source();

const sendGet = async (instance, path, queries = {}, options = {}) => {
  try {
    const urlWithQueries = queryString.stringifyUrl({
      url: path,
      query: queries
    });
    return await instance.get(urlWithQueries, options);
  } catch (error) {
    return errorHandler(error);
  }
};

const sendGetWithoutOptions = async (instance, urlWithQueries) => {
  try {
    return await instance.get(urlWithQueries);
  } catch (error) {
    return errorHandler(error);
  }
};

const sendPost = async (instance, path, body, options = {}) => {
  try {
    return await instance.post(path, { ...body }, options);
  } catch (error) {
    return errorHandler(error);
  }
};

const sendPatch = async (instance, path, body, options = {}) => {
  try {
    return await instance.put(path, { ...body }, options);
  } catch (error) {
    return errorHandler(error);
  }
};

const submit = async (
  instance,
  path,
  body,
  options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }
) => {
  try {
    const formData = new FormData();
    Object.entries(body).map(([key, value]) => formData.append(key, value));
    return await instance.post(path, formData, options);
  } catch (error) {
    return errorHandler(error);
  }
};

const sendDelete = async (instance, path, options = {}) => {
  try {
    return await instance.delete(path, options);
  } catch (error) {
    return errorHandler(error);
  }
};

const commonRequests = {
  sendPost,
  sendPatch,
  submit,
  sendGet,
  sendGetWithoutOptions,
  sendDelete,
  errorHandler,
  cancelSource
};

export default commonRequests;
