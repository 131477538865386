import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';

import atoms from '~/containers/Header/states/atoms';
import selectors from '~/containers/Header/states/selectors';
import { mediaQueryPreset } from '~/containers/shared/constants';
import './SearchIcon.scss';

export default function SearchIcon() {
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);
  const setIsOpenOverlaySearchBox = useSetRecoilState(
    atoms.isOpenOverlaySearchBox
  );
  const setSearchFocus = useSetRecoilState(
    selectors.searchFocusDropDownOpen(!isDesktop)
  );

  const handleOpen = useCallback(() => {
    setIsOpenOverlaySearchBox(true);
    setSearchFocus(true);
  }, [setIsOpenOverlaySearchBox, setSearchFocus]);

  return (
    <div
      role="button"
      tabIndex="-1"
      className="u-inline-block u-p-pointer u-t-nooutline"
      onClick={handleOpen}
    >
      <div className="c-nvCir u-inline-block is-ico">
        <span className="c-nvCir__well u-inline-block">
          <span
            className="c-nvCir__symbol ic-bef ic-site-search ic-bold u-inline-block u-t-body is-still"
            aria-hidden
          />
        </span>
      </div>
    </div>
  );
}
