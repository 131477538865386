/* eslint-disable react/no-danger */

import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';

import { trackMarqueeClick } from '~/containers/Header/analytics';

export const LINK_MODES = {
  ticker: 'ticker',
  callout: 'callout'
};

export default function MarqueeLink({
  blockName = '',
  mode = 'unknown_MarqueeLink',
  position = 'unknown_MarqueeLink',
  safeInnerHtml = {
    __html: ''
  },
  prefixIcon,
  suffixIcon,
  link,
  title
} = {}) {
  const isTicker = useMemo(() => mode === LINK_MODES.ticker, [mode]);

  const className = useMemo(
    () =>
      classNames('u-t-small u-t-white u-animate-all is-still', {
        'u-block': isTicker,
        [`${blockName}__item`]: !!blockName,
        'is-link': !!link
      }),
    [blockName, isTicker, link]
  );

  const innerContent = useMemo(
    () =>
      isTicker ? (
        <span dangerouslySetInnerHTML={safeInnerHtml} />
      ) : (
        <span className={`${blockName}__inner`}>
          {!!prefixIcon && (
            <span
              className={`${blockName}__ico is-prefix ic-bef ${prefixIcon}`}
              aria-hidden
            />
          )}
          <span
            className={`${blockName}__txt`}
            dangerouslySetInnerHTML={safeInnerHtml}
          />
          {!!suffixIcon && (
            <span
              className={`${blockName}__ico is-suffix ic-bef ${suffixIcon}`}
              aria-hidden
            />
          )}
        </span>
      ),
    [blockName, isTicker, prefixIcon, safeInnerHtml, suffixIcon]
  );

  const handleClick = useCallback(() => {
    const { __html: htmlContent } = safeInnerHtml;

    trackMarqueeClick({
      mode,
      position,
      title: htmlContent,
      url: link
    });
  }, [link, mode, position, safeInnerHtml]);

  if (!link)
    return (
      <div
        role="button"
        tabIndex={-1}
        title={title}
        className={className}
        onClick={handleClick}
      >
        {innerContent}
      </div>
    );

  return (
    <a href={link} title={title} className={className} onClick={handleClick}>
      {innerContent}
    </a>
  );
}
