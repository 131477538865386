import { useCallback, useEffect } from 'react';
import { noop } from 'lodash';

export default function useOutsideClicker(
  ref,
  onOutsideClick = noop,
  onInsideClick = noop
) {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      } else {
        onInsideClick();
      }
    },
    [onInsideClick, onOutsideClick, ref]
  );

  useEffect(() => {
    document.removeEventListener('click', handleClickOutside);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    document.removeEventListener('touchend', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [handleClickOutside]);
}
