import React from 'react';
import { noop } from 'lodash';

import useSearchSuggestions from '~/hooks/Header/useSearchSuggestions';
import searchHrefFor from '~/utils/Header/helper';
import './OverlaySearchBox.scss';

export default function PopularSearches({ onClick = noop }) {
  const suggestionList = useSearchSuggestions();

  return (
    <div className="c-popSearch__wrapper">
      <div className="c-popSearch__head u-t-bold">Popular Searches</div>
      <ul className="c-popSearch__line u-minied-list">
        {suggestionList.map((item) => (
          <li
            key={item?.id}
            className="c-popSearch__opt u-inline-block u-t-nowrap"
          >
            <a
              className="c-popSearch__pill u-t-nolined u-t-nooutline u-animate-all is-still"
              href={searchHrefFor(item)}
              title={item?.name}
              onClick={() => onClick(item)}
            >
              <span className="u-t-bold" />
              {item?.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
