import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty, noop } from 'lodash';
import Highlighter from 'react-highlight-words';

import atoms from '~/containers/Header/states/atoms';
import searchHrefFor from '~/utils/Header/helper';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { SUGGEST_SEARCH_TYPES } from '~/containers/SearchResult/constants';
import './OverlaySearchBox.scss';

export default function SearchResults({ onClick = noop }) {
  const searchQuery = useRecoilValue(atoms.navSearchQuery);
  const klevuNavSearchResults = useRecoilValue(atoms.klevuNavSearchResults);

  const searchResults = useMemo(
    () =>
      defaultForUndefinedOrNull(
        klevuNavSearchResults?.records?.map((i) => ({
          ...i,
          search_type: SUGGEST_SEARCH_TYPES.klevu
        })),
        []
      ),
    [klevuNavSearchResults?.records]
  );

  if (isEmpty(searchResults)) return null;

  return (
    <div className="c-olResult__wrapper">
      <ul className="c-olResult__list u-minied-list">
        {searchResults.map((item) => (
          <li key={item?.id} className="c-olResult__item u-block">
            <a
              className="c-olResult u-t-nolined u-t-nooutline u-animate-all is-still u-t-body"
              href={searchHrefFor(item)}
              title={item?.name}
              onClick={() => onClick(item)}
            >
              <Highlighter
                highlightClassName="u-t-bold"
                highlightStyle={{ padding: 'unset', backgroundColor: 'unset' }}
                searchWords={[searchQuery]}
                autoEscape
                textToHighlight={item?.name}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
