import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import queryString from 'query-string-for-all';
import {
  KlevuFetch,
  search as klevuSearch,
  KlevuTypeOfSearch,
  KlevuTypeOfRecord,
  KlevuEvents
} from '@klevu/core';
import { useMediaQuery } from 'react-responsive';
import { isEmpty, isFunction, noop, findIndex } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import selectors from '~/containers/Header/states/selectors';
import { mediaQueryPreset } from '~/containers/shared/constants';
import PopularSearches from '~/components/Header/ControlRow/OverlaySearchBox/PopularSearches';
import SearchResults from '~/components/Header/ControlRow/OverlaySearchBox/SearchResults';
import useDebounce from '~/hooks/shared/useDebounce';
import useSearchSuggestions from '~/hooks/Header/useSearchSuggestions';
import { RESULT_PAGE_URL } from '~/containers/SearchResult/constants';
import { keyboardKeys } from '~/containers/Header/constants';
import { localStorageHelper } from '~/utils/storageHelper';
import { defaultForUndefinedOrNull, humanListCount } from '~/utils/helper';
import { KLEVU_ABTEST_KEY } from '~/utils/Search/klevuHelper';
import { ga4Events } from '~/utils/analytics/gtm';
import searchHrefFor from '~/utils/Header/helper';
import { trackSearchSuggestAction } from '~/containers/SearchResult/analytics';
import './OverlaySearchBox.scss';

const DELAY_SEARCH_INTENT = 2000;
const DELAY_INPUT_ONCHANGE = 200;
const SEARCH_TYPE = 'Search Icon';

export default function OverlaySearchBox() {
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);
  const overlayRef = useRef(null);
  const intentRef = useRef(null);
  const intentDataRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useRecoilState(atoms.navSearchQuery);
  const setIsOpenOverlaySearchBox = useSetRecoilState(
    atoms.isOpenOverlaySearchBox
  );
  const [klevuNavSearchResults, setKlevuNavSearchResults] = useRecoilState(
    atoms.klevuNavSearchResults
  );
  const setSearchFocus = useSetRecoilState(
    selectors.searchFocusDropDownOpen(!isDesktop)
  );

  const suggestionList = useSearchSuggestions();

  const searchResults = useMemo(
    () => defaultForUndefinedOrNull(klevuNavSearchResults?.records, []),
    [klevuNavSearchResults?.records]
  );

  const cleanQuery = useMemo(() => searchQuery.trim(), [searchQuery]);

  const isShowPopularSearches = useMemo(
    () => isEmpty(searchQuery) || isEmpty(searchResults),
    [searchQuery, searchResults]
  );

  const handleCloseSearchOverlay = useCallback(
    () => setIsOpenOverlaySearchBox(false),
    [setIsOpenOverlaySearchBox]
  );

  const inputOnFocus = useCallback(() => setIsFocus(true), []);

  const inputOnBlur = useCallback(() => setIsFocus(false), []);

  const abTestInfo = useCallback(
    () =>
      defaultForUndefinedOrNull(
        localStorageHelper.getItem(KLEVU_ABTEST_KEY)?.[0],
        {}
      ),
    []
  );

  const search = useCallback(
    async (query) => {
      if (isEmpty(query)) return;
      const queryID = 'search_autocomplete';

      const res = await KlevuFetch(
        klevuSearch(query, {
          id: queryID,
          limit: 10,
          fields: ['id', 'name', 'url', 'category'],
          typeOfRecords: [KlevuTypeOfRecord.Category],
          typeOfSearch: KlevuTypeOfSearch.WildcardAnd,
          excludeIds: [{ key: 'itemGroupId', value: 'EXCLUDE' }]
        })
      );

      const searchResult = res.queriesById(queryID);
      if (!searchResult) return;

      const {
        getSearchClickSendEvent,
        meta: { totalResultsFound, searchedTerm, typeOfSearch } = {}
      } = searchResult;

      intentDataRef.current = {
        term: searchedTerm,
        totalResults: totalResultsFound,
        typeOfSearch,
        abTestId: abTestInfo()?.abTestId,
        abTestVariantId: abTestInfo()?.abTestVariantId
      };
      if (isFunction(getSearchClickSendEvent)) getSearchClickSendEvent();
      setKlevuNavSearchResults(searchResult);
    },
    [abTestInfo, setKlevuNavSearchResults]
  );

  const lazyQuery = useDebounce(() => search(cleanQuery), DELAY_INPUT_ONCHANGE);

  const trackIntent = useCallback((cb = noop) => {
    if (!isEmpty(intentDataRef.current)) {
      trackSearchSuggestAction(ga4Events?.view_search_suggest, {
        query: intentDataRef.current?.term,
        total: defaultForUndefinedOrNull(
          intentDataRef.current?.totalResults,
          0
        ),
        searchType: SEARCH_TYPE
      });
      KlevuEvents.search(intentDataRef.current);
      intentDataRef.current = null;
    }
    cb();
  }, []);

  const onSelect = useCallback(
    (selectedItem) => {
      const optionHref = searchHrefFor(selectedItem);

      trackSearchSuggestAction(ga4Events?.click_search_suggest, {
        query: cleanQuery,
        total: defaultForUndefinedOrNull(suggestionList?.length, 0),
        title: selectedItem?.name,
        url: optionHref,
        listPosition: humanListCount(
          findIndex(suggestionList, (r) => r.id === selectedItem?.id)
        ),
        mode: selectedItem?.search_type,
        trigger: 'popular',
        searchType: SEARCH_TYPE
      });

      trackIntent(() => {
        globalThis.location.href = optionHref;
      });
    },
    [cleanQuery, suggestionList, trackIntent]
  );

  const inputOnChange = useCallback(
    (ev) => {
      const query = defaultForUndefinedOrNull(ev.target.value, '');
      setSearchQuery(query);
      lazyQuery();
    },
    [lazyQuery, setSearchQuery]
  );

  const inputOnKeyDown = useCallback(
    (ev) => {
      if (ev.key === keyboardKeys.Enter && !isEmpty(cleanQuery)) {
        clearTimeout(intentRef?.current);
        localStorageHelper.setItem('search_type', SEARCH_TYPE);
        trackIntent(() => {
          globalThis.location.href = queryString.stringifyUrl({
            url: RESULT_PAGE_URL,
            query: {
              q: cleanQuery
            }
          });
        });
      }
    },
    [cleanQuery, trackIntent]
  );

  const inputOnKeyUp = useCallback(() => {
    clearTimeout(intentRef?.current);
    intentRef.current = setTimeout(trackIntent, DELAY_SEARCH_INTENT);
  }, [trackIntent]);

  const handleCloseSearchBox = useCallback(() => {
    if (isEmpty(searchQuery)) {
      handleCloseSearchOverlay();
      setSearchFocus(false);
    }
    setSearchQuery('');
  }, [handleCloseSearchOverlay, searchQuery, setSearchFocus, setSearchQuery]);

  return (
    <div className="">
      <div ref={overlayRef} className="c-olSearch">
        <div
          className={classNames('c-olSearch__wrapper', {
            'is-focus': isFocus
          })}
        >
          <div className="c-olSearch__field">
            <div className="c-olSearch__overlay u-animate-all">
              <span
                className="c-olSearch__icon ic-bef ic-site-search ic-bold u-inline-block u-t-body is-still"
                aria-hidden
              />
            </div>
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={searchQuery}
              className="c-olSearch__input u-animate-all"
              placeholder="Search products"
              onChange={inputOnChange}
              onFocus={inputOnFocus}
              onBlur={inputOnBlur}
              onKeyUp={inputOnKeyUp}
              onKeyDown={inputOnKeyDown}
            />
            <div
              role="button"
              tabIndex="-1"
              className="c-olSearch__well u-inline-block u-p-pointer u-t-nooutline"
              onClick={handleCloseSearchBox}
            >
              <span
                className="c-olSearch__icon ic-bef ic-site-cross ic-bold u-inline-block u-t-body is-still"
                aria-hidden
              />
            </div>
          </div>
        </div>
      </div>
      {isShowPopularSearches ? (
        <div className="c-popSearch">
          <PopularSearches onClick={onSelect} />
        </div>
      ) : (
        <div className="c-olResult">
          <SearchResults onClick={onSelect} />
        </div>
      )}
    </div>
  );
}
