import { merge } from 'lodash';
import TagManager from 'react-gtm-module';
import { Cookies } from 'react-cookie';

import RailsVars from '~/apps/railsVariables.js.erb';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { amplitudeTrack } from '~/utils/analytics/amplitude';

const gtmTriggers = {
  zendeskWidget: 'zd_update',
  metaCollection: 'meta_collection',
  metaSearch: 'meta_search',
  metaProduct: 'meta_product',
  metaCart: 'meta_cart',
  metaPurchase: 'meta_purchase',

  ga4Custom: 'ga4_custom_event_basic',
  ga4UaEvent: 'ga4_ua_event',

  pageError: 'ga4_page_error',
  userAuth: 'ga4_auth',
  userAuthFlow: 'ga4_auth_flow',
  userAuthNative: 'ga4_auth_native',
  userInfoRequest: 'ga4_info_request',
  userShare: 'ga4_share',
  userSubscribe: 'ga4_subscribe',
  cmsLink: 'ga4_cms_link',

  shopNav: 'ga4_nav',
  shopWidget: 'ga4_shop_widget',
  shopCalloutMarquee: 'ga4_marquee',
  shopCalloutModal: 'ga4_callout_modal',
  shopSearchSuggest: 'ga4_search_suggest',
  shopFilter: 'ga4_shop_filter',
  shopFilterApply: 'ga4_shop_filter_apply',
  shopFilterIntent: 'ga4_shop_filter_intent',
  shopBrokenImage: 'ga4_broken_image',
  shopListEmpty: 'ga4_list_empty',
  shopListAction: 'ga4_list_action',
  shopListCtr: 'ga4_list_ctr',
  shopListBanner: 'ga4_list_banner',
  shopListPreviewAction: 'ga4_list_preview_action',
  shopListPreviewExpand: 'ga4_list_preview_expand',
  shopIntentAction: 'ga4_intent_action',
  shopCheckoutStep: 'ga4_checkout_step',
  shopPurchase: 'ga4_purchase',

  contentFilter: 'ga4_content_filter',
  contentImpression: 'ga4_content_impression',
  contentViewList: 'ga4_content_view_list',
  contentViewId: 'ga4_content_view_id',
  contentClickId: 'ga4_content_click_id',
  contentNewStart: 'ga4_content_new_start',
  contentNewInit: 'ga4_content_new_init',
  contentNewSelected: 'ga4_content_new_selected',
  contentNewNextPic: 'ga4_content_new_next',
  contentNewFinalise: 'ga4_content_new_finalise',
  contentNewSuccess: 'ga4_content_new_success',
  contentEnjoyPost: 'ga4_content_enjoy_post',
  contentHashtag: 'ga4_content_hashtag',
  contentOffer: 'ga4_content_offer',
  contentSaveOffer: 'ga4_content_save_offer',
  contentProfile: 'ga4_content_profile',

  userNotif: 'ga4_user_notif',
  userPrefs: 'ga4_user_prefs'
};

const ga4Events = {
  sign_up: 'sign_up',
  login: 'login',
  share: 'share',
  search: 'search',
  view_item_list: 'view_item_list',
  select_item: 'select_item',
  view_item: 'view_item',
  add_to_cart: 'add_to_cart',
  remove_from_cart: 'remove_from_cart',
  view_cart: 'view_cart',
  add_to_wishlist: 'add_to_wishlist',
  remove_from_wishlist: 'remove_from_wishlist',
  begin_checkout: 'begin_checkout',
  add_shipping_info: 'add_shipping_info',
  add_payment_info: 'add_payment_info',
  purchase: 'purchase',

  view_error: 'view_error',
  click_callout_marquee: 'click_marquee',
  view_callout_sticky_promo: 'view_callout_sticky_promo',
  click_callout_sticky_promo: 'click_callout_sticky_promo',
  close_callout_sticky_promo: 'close_callout_sticky_promo',
  view_callout_app_nudge: 'view_callout_app_nudge',
  click_callout_app_nudge: 'click_callout_app_nudge',
  close_callout_app_nudge: 'close_callout_app_nudge',
  view_callout_lead_sub: 'view_callout_lead_sub',
  submit_callout_lead_sub: 'submit_callout_lead_sub',
  close_callout_lead_sub: 'close_callout_lead_sub',
  view_callout_exit_intent: 'view_callout_exit_intent',
  copy_callout_exit_intent: 'copy_callout_exit_intent',
  close_callout_exit_intent: 'close_callout_exit_intent',
  nav_view_burger: 'nav_view_burger',
  nav_view_menu: 'nav_view_menu',
  nav_click_link: 'nav_click_link',
  view_broken_image: 'view_broken_image',
  view_item_list_empty: 'view_item_list_empty',
  view_search_suggest: 'view_search_suggest',
  click_search_suggest: 'click_search_suggest',
  subscribe_nl: 'subscribe_nl',

  view_sign_in_modal: 'view_sign_in_modal',
  auth_attempt: 'auth_attempt',
  auth_response: 'auth_response',
  click_sign_in_modal_email: 'click_sign_in_modal_email',
  click_sign_in_modal_facebook: 'click_sign_in_modal_facebook',
  click_sign_in_modal_google: 'click_sign_in_modal_google',
  click_sign_in_email_submit: 'click_sign_in_email_submit',
  click_info_request_modal_success: 'click_info_request_modal_success',
  click_info_request_modal_close: 'click_info_request_modal_close',
  view_info_request_modal: 'view_info_request_modal',
  shop_filter_menu_open: 'shop_filter_menu_open',
  shop_filter_menu_active: 'shop_filter_menu_active',
  shop_filter_sort_option: 'shop_filter_sort_option',
  shop_filter_success: 'shop_filter_success',
  shop_collection_view: 'shop_collection_view',
  shop_collection_view_banner: 'view_collection_banner',
  shop_collection_click_banner: 'click_collection_banner',
  shop_collection_ctr: 'shop_collection_ctr',
  shop_search_ctr: 'shop_search_ctr',
  shop_preview_open: 'shop_preview_open',
  shop_preview_expand: 'shop_preview_expand',
  shop_preview_ctr: 'shop_preview_ctr',
  shop_preview_duration: 'shop_preview_duration',
  shop_preview_failure: 'shop_preview_failure',
  select_item_sorted: 'select_item_sorted',
  select_item_searched: 'select_item_searched',
  click_filter_sort_option: 'click_filter_sort_option',
  click_filter_topic_option: 'click_filter_topic_option',
  click_filter_modal_success: 'click_filter_modal_success',

  click_reviews_list_product: 'click_reviews_list_product',
  click_reviews_widget_summary: 'click_reviews_widget_summary',
  click_reviews_widget_prev: 'click_reviews_widget_prev',
  click_reviews_widget_next: 'click_reviews_widget_next',
  click_reviews_widget_product: 'click_reviews_widget_product',
  click_recents_widget_prev: 'click_recents_widget_prev',
  click_recents_widget_next: 'click_recents_widget_next',
  click_recents_widget_product: 'click_recents_widget_product',
  click_recommend_widget_product: 'click_recommend_widget_product',

  view_content_list: 'view_content_listing_page',
  view_content_id: 'view_content_detail_page',
  select_content_id: 'click_content_detail_page',
  view_offer_tag_popup: 'view_offer_tag_popup',
  click_offer_tag_popup: 'click_offer_tag_popup',
  click_hashtag: 'click_hashtag',

  like_post_on: 'like_post_on',
  like_post_off: 'like_post_off',
  save_post_on: 'save_post_on',
  save_post_off: 'save_post_off',
  save_offer_on: 'save_offer_on',
  save_offer_off: 'save_offer_off',
  follow_profile_on: 'follow_profile_on',
  follow_profile_off: 'follow_profile_off',
  dismiss_profile: 'dismiss_profile',
  click_profile: 'click_profile',
  click_new_post_start: 'click_new_post_start',
  click_new_post_initiate: 'click_new_post_initiate',
  click_new_post_selected: 'click_new_post_pics_selected',
  click_new_post_next_pic: 'click_new_post_next_pic',
  click_new_post_finalise: 'click_new_post_finalise_post',
  click_new_post_success: 'click_new_post_success',

  click_inbox_line_read: 'click_inbox_line_read',
  click_inbox_line_link: 'click_inbox_line_link',
  click_inbox_message: 'click_inbox_message',
  click_preference_switch: 'click_preference_switch'
};

const cachedPageViewData = () =>
  JSON.parse(sessionStorage.getItem(RailsVars.PAGE_VIEW_KEY));

const pagePathQueryFragment = (hasSearch = true, hasHash = true) => {
  if (typeof window !== 'object') return undefined;
  if (globalThis?.isMobileApp) return undefined;

  const {
    pathname = '',
    search = '',
    hash = ''
  } = defaultForUndefinedOrNull(globalThis?.location, {});
  return [pathname, hasSearch && search, hasHash && hash]
    .filter((s) => !!s)
    .join('');
};

const dataLayerPush = (data) => {
  if (typeof window !== 'object') return;
  if (globalThis?.isMobileApp) return;

  TagManager.dataLayer({
    gtmId: RailsVars.GOOGLE_TAG_MANAGER_KEY,
    dataLayer: data
  });
};

const customEventGa4 = (
  trigger = 'unknown_customEventGa4',
  eventName = 'unknown_customEventGa4',
  params = {}
) => {
  const resetUpcomingProps = Object.keys(params).reduce((acc, cur) => {
    acc[cur] = null;
    return acc;
  }, {});
  dataLayerPush(resetUpcomingProps);
  dataLayerPush(merge({ event: trigger, event_name: eventName }, params));
};

const uaEventGa4 = ({
  uaCategory,
  uaAction,
  uaLabel,
  uaValue,
  uaNonInteract,
  onlyGa4 = false
} = {}) => {
  const payload = {
    gaEvtCategory: uaCategory,
    gaEvtAction: uaAction,
    gaEvtLabel: uaLabel,
    gaEvtValue: uaValue,
    gaEvtNonInteract: uaNonInteract
  };
  dataLayerPush({
    event: gtmTriggers?.ga4UaEvent,
    ...payload
  });
  if (!onlyGa4) amplitudeTrack(gtmTriggers?.ga4UaEvent, payload);
};

const fbqEvents = {
  trackStandard: 'track',
  trackCustom: 'trackCustom',
  cevtViewCategory: 'ViewCategory',
  sevtViewContent: 'ViewContent',
  sevtSearch: 'Search'
};

const fbqEventGa4 = ({ fbqType, fbqName, fbqData, fbqEventID } = {}) => {
  let eventPayload = fbqData;
  if (globalThis?.HV?.em || globalThis?.CURRENT_USER_DATA) {
    const cookies = new Cookies();
    const userData = {
      user_data: {
        em: globalThis?.HV?.em || globalThis?.CURRENT_USER_DATA?.em,
        ph: globalThis?.HV?.ph || globalThis?.CURRENT_USER_DATA?.ph,
        fb_login_id: globalThis?.CURRENT_USER_DATA?.facebook_id,
        fbc: cookies.get('_fbc'),
        fbp: cookies.get('_fbp'),
        external_id:
          globalThis?.CURRENT_USER_DATA?.external_id ||
          globalThis?.HV?.DEFAULT_EXTERNAL_ID
      }
    };
    eventPayload = { ...fbqData, ...userData };
  }

  return dataLayerPush({
    event: 'fbqEvent',
    fbqType,
    fbqName,
    fbqData: eventPayload,
    fbqEventID
  });
};

const ttqEvents = {
  sevtViewContent: 'ViewContent',
  sevtSearch: 'Search',
  sevtAddToWishlist: 'AddToWishlist'
};

const ttqEventGa4 = ({ ttqName, ttqData, ttqEventID } = {}) =>
  dataLayerPush({
    event: 'ttqEvent',
    ttqName,
    ttqData,
    ttqEventID
  });

const ptqEvents = {
  trackStandard: 'track',
  cevtViewCategory: 'viewcategory',
  sevtViewContent: 'pagevisit',
  sevtSearch: 'search'
};

const ptqEventGa4 = ({ ptqType, ptqName, ptqData = {}, ptqEventID } = {}) =>
  dataLayerPush({
    event: 'ptqEvent',
    ptqType,
    ptqName,
    ptqData: {
      ...ptqData,
      event_id: ptqEventID
    }
  });

const trackAuthTrigger = ({ mode, trigger, type = 'unknown', id } = {}) => {
  const ga4Payload = {
    mode,
    location: pagePathQueryFragment(),
    trigger,
    id: [type, id].filter((s) => !!s).join('-'),
    referrer: cachedPageViewData()?.previousPage?.path
  };
  customEventGa4(gtmTriggers?.userAuthFlow, ga4Events?.view_sign_in_modal, {
    mode,
    location: pagePathQueryFragment(),
    trigger,
    id: [type, id].filter((s) => !!s).join('-'),
    referrer: cachedPageViewData()?.previousPage?.path
  });
  amplitudeTrack(ga4Events?.view_sign_in_modal, ga4Payload);
};

const trackClickAuthEmailSubmit = () => {
  const eventName = ga4Events?.click_sign_in_email_submit;
  const ga4Payload = { location: globalThis?.location?.pathname };
  customEventGa4(gtmTriggers?.ga4Custom, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackClickAuthMethod = (provider = 'unknown', mode = 'modal') => {
  const eventName = defaultForUndefinedOrNull(
    ga4Events?.[`click_sign_in_${mode}_${provider}`],
    'click_sign_in_unknown_unknown'
  );
  customEventGa4(gtmTriggers?.ga4Custom, eventName);
  amplitudeTrack(eventName, {});
};

const trackAuthResponse = ({ authProvider = 'native', response } = {}) => {
  const ga4Payload = {
    label: authProvider,
    value: response,
    referrer: cachedPageViewData()?.previousPage?.path
  };
  customEventGa4(gtmTriggers?.userAuthFlow, ga4Events?.auth_response, {
    label: authProvider,
    value: response,
    referrer: cachedPageViewData()?.previousPage?.path
  });
  amplitudeTrack(ga4Events?.auth_response, ga4Payload);
};

const trackAuthLead = ({ mode, content, authProvider = 'native' } = {}) => {
  const ga4Payload = {
    mode,
    location: cachedPageViewData()?.currentPage?.path,
    trigger: content,
    label: authProvider,
    referrer: cachedPageViewData()?.previousPage?.path
  };
  customEventGa4(
    gtmTriggers?.userAuthFlow,
    ga4Events?.auth_attempt,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.auth_attempt, ga4Payload);
};

const trackNLSub = (mode, response) => {
  const ga4Payload = {
    mode,
    value: response
  };
  customEventGa4(
    gtmTriggers?.userSubscribe,
    ga4Events?.subscribe_nl,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.subscribe_nl, ga4Payload);
};

export {
  TagManager,
  gtmTriggers,
  ga4Events,
  cachedPageViewData,
  pagePathQueryFragment,
  dataLayerPush,
  customEventGa4,
  uaEventGa4,
  fbqEvents,
  fbqEventGa4,
  ttqEvents,
  ttqEventGa4,
  ptqEvents,
  ptqEventGa4,
  trackAuthTrigger,
  trackClickAuthEmailSubmit,
  trackClickAuthMethod,
  trackAuthResponse,
  trackAuthLead,
  trackNLSub
};
