import React, { useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { delay } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import selectors from '~/containers/Header/states/selectors';
import { mediaQueryPreset } from '~/containers/shared/constants';
import NavLogo from '~/components/Header/NavLogo';
import NavSearch from '~/components/Header/NavSearch';
import BurgerIcon from '~/components/Header/ControlRow/BurgerIcon';
import AccountIcon from '~/components/Header/ControlRow/AccountIcon';
import CartIcon from '~/components/Header/ControlRow/CartIcon';
import SearchIcon from '~/components/Header/ControlRow/SearchIcon';
import OverlaySearchBox from '~/components/Header/ControlRow/OverlaySearchBox';
import useOutsideClicker from '~/hooks/shared/useOutsideClicker';
import './ControlMobile.scss';

const DELAY_DISMISS_OVERLAY_SEARCH = 200;

export default function NavControlMobile() {
  const navMenuMoblRef = useRef(null);
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);
  const [isOpenOverlaySearchBox, setIsOpenOverlaySearchBox] = useRecoilState(
    atoms.isOpenOverlaySearchBox
  );
  const setSearchFocus = useSetRecoilState(
    selectors.searchFocusDropDownOpen(!isDesktop)
  );

  const handleCloseSearchOverlay = useCallback(() => {
    if (isOpenOverlaySearchBox) {
      setIsOpenOverlaySearchBox(false);
      delay(() => setSearchFocus(false), [DELAY_DISMISS_OVERLAY_SEARCH]);
    }
  }, [isOpenOverlaySearchBox, setIsOpenOverlaySearchBox, setSearchFocus]);

  useOutsideClicker(navMenuMoblRef, handleCloseSearchOverlay);

  return (
    <div ref={navMenuMoblRef}>
      <div
        className={classNames('c-nvMenuMobl', {
          hidden: isOpenOverlaySearchBox
        })}
      >
        <div className="c-nvMenuMobl__seg is-left">
          <BurgerIcon />
          {!isDesktop && <SearchIcon />}
        </div>
        <div className="c-nvMenuMobl__seg is-mid">
          <NavLogo />
        </div>
        <div className="c-nvMenuMobl__seg is-right">
          <div className="c-nvMenuMobl__actions">
            <div className="c-nvMenuMobl__search">
              <NavSearch />
            </div>
            <AccountIcon />
            <CartIcon />
          </div>
        </div>
      </div>
      {isOpenOverlaySearchBox && <OverlaySearchBox />}
    </div>
  );
}
