import { useState, useCallback, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';

import { humanListCount, currentTimeVisible } from '~/utils/helper';

export const FALLBACK_MESSAGE = {
  label: 'Free Shipping Over $300 →'
};

export default function useMarqueeData() {
  const [marqInterval, setMarqInterval] = useState();
  const [tickerItems, setTickerItems] = useState([FALLBACK_MESSAGE]);
  const [calloutItems, setCalloutItems] = useState([]);

  const currentlyVisible = useCallback(
    (props) => currentTimeVisible(props?.start, props?.end),
    []
  );

  const sanitisedResponsiveInnerMessage = useCallback(
    (richContent) => ({
      __html: DOMPurify.sanitize(richContent)
    }),
    []
  );

  const cacheSafeData = useCallback(
    ({ interval, ticker = [], callouts = [] } = {}) => {
      if (!isEmpty(interval)) setMarqInterval(interval);

      if (!isEmpty(callouts))
        setCalloutItems(
          callouts
            .filter(currentlyVisible)
            .slice(0, 3)
            .map((props, idx) => ({
              id: props?.label,
              position: humanListCount(idx),
              safeInnerHtml: sanitisedResponsiveInnerMessage(props?.label),
              prefixIcon: props?.prefixIcon,
              suffixIcon: props?.suffixIcon,
              link: props?.link,
              start: props?.start,
              end: props?.end,
              tooltip: props?.tooltip
            }))
        );

      if (!isEmpty(ticker))
        setTickerItems(
          ticker.filter(currentlyVisible).map((props, idx) => ({
            id: props?.label,
            position: humanListCount(idx),
            safeInnerHtml: sanitisedResponsiveInnerMessage(props?.label),
            link: props?.link,
            start: props?.start,
            end: props?.end,
            tooltip: props?.tooltip
          }))
        );
    },
    [currentlyVisible, sanitisedResponsiveInnerMessage]
  );

  useEffect(() => {
    const initData = isEmpty(globalThis?.MARQ_CONFIG)
      ? {
          interval: undefined,
          ticker: [FALLBACK_MESSAGE],
          callouts: []
        }
      : globalThis?.MARQ_CONFIG;
    cacheSafeData(initData);
  }, [cacheSafeData]);

  return {
    interval: marqInterval,
    ticker: tickerItems,
    callouts: calloutItems
  };
}
